exports.components = {
  "component---src-components-templates-categorytemplate-tsx": () => import("./../../../src/components/templates/categorytemplate.tsx" /* webpackChunkName: "component---src-components-templates-categorytemplate-tsx" */),
  "component---src-components-templates-productitemtemplate-tsx": () => import("./../../../src/components/templates/productitemtemplate.tsx" /* webpackChunkName: "component---src-components-templates-productitemtemplate-tsx" */),
  "component---src-components-templates-subcategorytemplate-tsx": () => import("./../../../src/components/templates/subcategorytemplate.tsx" /* webpackChunkName: "component---src-components-templates-subcategorytemplate-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-contentful-blog-post-title-js": () => import("./../../../src/pages/blogs/{contentfulBlogPost.title}.js" /* webpackChunkName: "component---src-pages-blogs-contentful-blog-post-title-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-strapi-product-name-js": () => import("./../../../src/pages/products/{strapiProduct.name}.js" /* webpackChunkName: "component---src-pages-products-strapi-product-name-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user/[...].js" /* webpackChunkName: "component---src-pages-user-js" */)
}

