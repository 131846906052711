import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { SuspenseWithPerf } from 'reactfire'
import styled from 'styled-components'

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-content: cneter;
    width: 100%;
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #fff;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }
    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }
    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }
    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%,
        100% {
            top: 24px;
            height: 32px;
        }
    }
`

export default function Errorwrapper({ children }: any) {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallBack}>
            <SuspenseWithPerf
                fallback={
                    <Loading>
                        <div className="lds-facebook">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </Loading>
                }
                traceId="p"
            >
                {children}
            </SuspenseWithPerf>
        </ErrorBoundary>
    )
}

function ErrorFallBack({ error }: any) {
    return (
        <div role="alert">
            <p>Error loading projects:</p>
            <pre>{error.message}</pre>
        </div>
    )
}
